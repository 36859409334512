@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --navigation-tabs-height: calc(75px + env(safe-area-inset-bottom));
    --navigation-compare-section-height: 0px; // will be 44px when active 
}

.NavigationTabs {
    border-top: none;

    @include mobile {
        z-index: 310;
    }

    &-Icon {
        font-weight: $fw-regular;
    }

    &-Button {
        @include flex($dir: column, $align: center, $justify: center);

        width: calc(100% / 5);
        font-size: 9px;

        span {
            margin-block-start: 10px;

            &.isActive {
                color: $color-primary;
            }
        }

        .Header {
            &-MinicartItemCount {
                @include mobile {
                    margin: 0;
                    inset-block-start: -8px;
                }
            }
        }

        .NavigationTabs {

            /* stylelint-disable-next-line */
            &-CompareCount,
            &-WishlistCount {
                background: $error;
                font-family: inherit;
                font-size: $fs-xxxs;
                line-height: 1.6;
                font-weight: $fw-semi-bold;
                border: solid 2px $white;
                width: 19px;
                height: 19px;
                inset-block-start: -7px;
                inset-inline-end: -10px;
                border-radius: 50%;
                color: $white;
                padding: 2px;
                position: absolute;
                margin: 0;

                @include flex($align: center, $justify: center);
            }

            &-IconWrapper {
                margin: 0;
            }
        }
    }

    &-Nav {
        border-top: 1px solid var(--primary-divider-color);

        @include mobile {
            padding: 14px;
        }
    }

    .Header-Button_type_minicart {
        @include flex($dir: column, $align: center, $justify: center);

        height: 100%;
    }

    &-CompareSection {
        height: var(--navigation-compare-section-height);
        text-align: center;
        background: #FFDEA8B2;
        color: $color-primary;
        font-size: $fs-s;
        font-weight: $fw-medium;

        a {
            display: block;
            padding: 12px 0;
        }
    }
}
