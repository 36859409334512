@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchIcon {
    pointer-events: none;
    color: var(--color-black);

    * {
        color: var(--color-black);
    }

    &_isActive {
        color: var(--primary-base-color);

        * {
            color: var(--primary-base-color);
        }
    }
}
