@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --search-bar-max-width: 553px;
    --search-bar-width: 55%;
    --search-field-background: #{$gray-lighter};
    --search-field-border-color: #{$gray-lighter};
    --search-field-color: #{$black};
    --search-field-visible-background: #{$gray-lighter};
    --search-field-focus-border-color: #{$gray-light};
}

@mixin search-bar-font-style {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: var(--search-field-color);
}

.SearchField {
    @include desktop {
        width: var(--search-bar-width);
        max-width: var(--search-bar-max-width);
        margin: 0 auto;
    }

    @include ultra-narrow-desktop {
        width: 45%;
    }

    @include tablet {
        width: 30%;
    }

    @include mobile {
        position: absolute;
        inset-block-start: 55px;
        inset-inline-start: 0;
        inset-inline-end: 0;
        padding: 10px;
        z-index: 400;
        margin: 0;
    }

    &-Input {
        border-color: var(--search-field-border-color);
        background-color: var(--search-field-background);
        border-radius: unset;
        padding: 11px 16px;

        @include search-bar-font-style;

        &_isActive {
            --search-field-background: #{$white};

            @include mobile {
                --search-field-background: #{$gray-lighter};
            }

            &:focus {
                border-color: var(--search-field-focus-border-color);
            }

            &.SearchField-Input_isOpenOverlay {
                border-right: none;
            }
        }

        &:not(:placeholder-shown) {
            border-color: var(--search-field-focus-border-color) var(--search-field-focus-border-color) $white var(--search-field-focus-border-color);
        }

        &::placeholder {
            @include search-bar-font-style;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: flex;
                padding: 14px 18px;

                &_isExtraResults {
                    display: block;

                    @include mobileAndTablet {
                        display: none;
                    }
                }
            }
        }
    }
}
