@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

/* stylelint-disable no-duplicate-selectors */
$debug-hover: false;

:root {
    --header-layer-height: 92px;
    --header-layer-menu-height: 72px;
    --header-bottom-layer-height: 72px;
    --header-bottom-layer-offset: calc(var(--navigation-tabs-height) + var(--navigation-compare-section-height) + 4px);
}

.HeaderFollowing {
    &-Layer, &-LayerBottom {
        position: fixed;
        width: 100vw;
        z-index: 101;
        inset-inline-start: 0;
    }

    &-Layer {
        height: var(--header-layer-height);
        background-color: $white;
        border-bottom: 1px solid $gray-lighter;
        inset-block-start: calc(-1 * var(--header-layer-height));
        transition: top .25s ease-in-out;
        will-change: top;

        &_isVisible {
            inset-block-start: calc(0px + var(--demo-notice-height));
        }
    }

    &-LayerBottom {
        height: var(--header-bottom-layer-height);
        inset-block-end: calc(-1 * var(--header-bottom-layer-height));
        transition: bottom .25s ease-in-out;
        will-change: bottom;

        &_isVisible {
            inset-block-end: var(--header-bottom-layer-offset);
        }
    }

    &-MainHeader {
        position: relative;
    }

    &-StartContainer {
        display: flex;
        align-items: center;
    }

    &-MenuWrapper {
        position: fixed;
        inset-block-start: 0px;
        inset-inline-start: 0px;
        height: 0;
        width: 100vw;
        background-color: $white;
        border-bottom: 1px solid $gray-light;
        z-index: 100;
        overflow-y: hidden;
        transition: top .25s ease-in-out, height .25s ease-in-out;
        will-change: top;

        &_isVisible {
            @include applyAttributeAfterDelay($attribute: z-index, $startVal: 100, $endVal: 101, $delay: .25s);
            
            height: var(--header-layer-menu-height);
            inset-block-start: calc(var(--header-layer-height) + var(--demo-notice-height));
            overflow-y: initial;
        }

        .Menu { 
            &-MenuWrapper {
                margin-block-start: 0
            }

            &-SubCategoriesWrapper {
                inset-block-start: var(--header-layer-menu-height);
            }

            &-ItemList {
                padding-block: 20px;
            }

            &-ItemWrapper_type_main {
                position: relative;

                &:hover {
                    border-bottom: none
                }
            }

            &-Item {
                @include tablet {
                    margin-inline-start: 12px;
                }
            }

            &-Link {
                display: inline-block;
                margin: 0;
                margin-inline-end: 0;

                &:hover {
                    border-bottom: none;
                }
                
                &::before, &::after {
                    opacity: 0;
                    transition: opacity .25s ease-in-out;
                    will-change: opacity;
                }

                &_isHovered {
                    border-bottom: none;
                  
                    &::before, &::after {
                        opacity: 1;
                        inset-block-end: -32px;
                    }  

                    &::after {
                        content: '';
                        position: absolute;
                        height: 1px;
                        width: 100%;
                        background-color: $default-primary-base-color;
                        z-index: 1002;
                    }

                    &::before {
                        position: absolute;
                        inset-inline-start: 50%;
                        transform: translateX(-50%);
                    }
                }
            }

            &-Item {
                &_buttonView {
                    .Menu-Link {
                        &::before, &::after {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    &-MenuSwitch {
        display: grid;
        place-items: center;
        width: 40px;
        height: 40px;
        margin-inline-end: 32px;
        background-color: rgba($color-primary-light, .15);
        cursor: pointer;
        z-index: 101;
        transition: background-color .25s ease-in-out;
        will-change: background-color;
        
        &:hover {
            background-color: rgba($color-primary-light, .35);
        }

        &_isActive, &_isActive:hover {
            background-color: $default-primary-base-color;
        }

        &Bridge {
            // bridge connecting burger menu and menu items to avoid closing layer
            content: '';
            position: absolute;
            display: block;
            height: calc(100% + 15px + 32px);
            width: calc(100% + 2 * 15px);
            padding: 15px;
            padding-block-end: 32px;
            inset-block-start: -16px;
            z-index: 100;
            
            @if $debug-hover == true {
                background: rgba(green, 0.5);
            }
        }
    }

    &-MenuSwitchCursorCatchLayer {
        position: fixed;
        inset-block-start: 0;
        inset-inline-start: 0;
        height: 100vh;
        width: 100vw;
        z-index: 99;

        @if $debug-hover == true {
            background-color: rgba(red, 0.5);
        }
    }

    /* MAIN HEADER LAYER */


    .SearchField {
        @include ultra-narrow-desktop {
            width: 100%;
        }
    
        @include tablet {
            width: 100%;
        }
    }

    &-Icons {
        display: flex;
        gap: 32px;

        @include tablet {
            gap: 16px;
        }
    }

    .ContentWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @include above1480 {
            padding-inline: 24px;
        }

        @include tabletAndDesktop {
            padding-inline: 32px;
        }
    }

    .Logo {
        display: inline-block;
    }

    .SearchField {
        --search-bar-width: 552px;
    }

    .Header {
        &-IconWrapper {
            margin-block-start: 0;
            height: 45px;
            min-width: 62px;

            &:hover {
                text-decoration: underline;
            }
            
            svg {
                scale: 0.95;
            }
        }

        &-IconLabel {
            text-wrap: nowrap;
        }

        &-CompareButtonWrapper, &-Button_type_wishlist, &-Button_type_minicart {
            margin-inline-start: 0;
        } 

        &-CompareCount {
            inset-block-start: -7px;
            inset-inline-end: 9px;
        }
    }

    .Menu { 
        &-ItemWrapper {
            padding-block-end: 0;
        }

        &-MainCategories {
            @include above1480 {
                padding-inline: 24px;
            }

            @include tabletAndDesktop {
                padding-inline: 32px;
            }
            
            &-ItemList {
                @include desktop {
                    padding-block-end: 0;
                }
            }
        }
    }

    /* *** */

    &-Layer {
        border-bottom: 1px solid $gray-lighter;
        transition: top .25s ease-in-out, border-bottom-color .25s ease-in-out;
        will-change: top, border-bottom-color;

        &_isSublayerExpanded {
            border-bottom-color: transparent
        }

        &_type {
            &_ADD_TO_CART_LAYER, &_ASK_ABOUT_ARRANGEMENT_LAYER {
                .ContentWrapper {
                    max-width: var(--content-wrapper-width)
                } 

                .HeaderFollowing {
                    &-Product {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        gap: 16px;
                        flex: auto;
                    }

                    &-ProductImage .Image-Image, &-ProductImage.Image {
                        height: 64px;
                        max-width: 64px;
                    }

                    &-ProductName {
                        flex: auto;
                        font-size: $fs-xs;
                        font-weight: $fw-medium;
                    }

                    &-Actions {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 14px;
                        height: 48px;
                        min-width: fit-content;

                        .CartIcon {
                            display: none;
                        }

                        .HeaderFollowing {
                            &-Price {
                                font-weight: $fw-medium;
                                font-size: $fs-xl;
                                letter-spacing: -0.03em;
                                margin-inline-end: 18px;

                                &_isPromo {
                                    color: $red;
                                }
                            }

                            &-BackToTop {
                                display: grid;
                                place-items: center;
                                height: 100%;
                                background-color: $white;
                                border-radius: 5px;
                                cursor: pointer;
                                transition: background-color 0.25s ease-in-out;
                                
                                &:hover {
                                    background-color: $gray-light;
                                }
                            }
                        }
                    }
                }
            }

            &_ADD_TO_CART_LAYER {
                .AddToCart {
                    padding-inline: 0;
                }

                .HeaderFollowing-BackToTop {
                    width: 98px;
                }
            }

            &_ASK_ABOUT_ARRANGEMENT_LAYER {
                .HeaderFollowing-AskAboutButton .Button {
                    color: $black;
                    background-color: $color-tertiary;
                    border: none;

                    &:hover {
                        color: $black;
                        background-color: $color-tertiary
                    }
                }

                .HeaderFollowing-BackToTop {
                    width: 58px;
                }
            }
        }
    }

    &-LayerBottom {
        &_type {
            &_ADD_TO_CART_LAYER {
                @include mobile {
                    .ContentWrapper {
                        padding-inline: 16px;
                    }
                }
            }

            &_ADD_TO_CART_LAYER {
                width: calc(100% - 63px); // chat icon space
                inset-inline-end: 0;
                inset-inline-start: initial;
            }

            &_ASK_ABOUT_ARRANGEMENT_LAYER {
                .HeaderFollowing-AskAboutButton {
                    flex: auto;

                    .Button {
                        color: $black;
                        background-color: $color-tertiary;
                        border: none;
    
                        &:hover {
                            color: $black;
                            background-color: $color-tertiary
                        }
                    }
                }
            }
        }
    }

    &-Layer_type_ADD_TO_CART_LAYER, &-Layer_type_ASK_ABOUT_ARRANGEMENT_LAYER {
        z-index: 100;

        &.HeaderFollowing-Layer_isVisible {
            inset-block-start: calc(1 * var(--header-layer-height) + var(--demo-notice-height));
        }
    }
}
